<template>
  <v-container class="mt-16">
    <div class="">
      <div>
        <v-card flat>
          <v-card-title class="d-flex">
            <h2>Chart of Accounts</h2>
            <v-spacer></v-spacer>
            <add-chart-of-account-modal />
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="search" label="Search"></v-text-field>
            <v-data-table
              :headers="headers"
              :items="chart_of_accounts"
              :search="search"
              :options="{ itemsPerPage: 30 }"
            >
              <template v-slot:item.actions="{ item }">
                <edit-chart-of-account-modal :item="item" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import addChartOfAccountModal from "../components/ChartOfAccounts/addChartOfAccountModal.vue";
import EditChartOfAccountModal from "../components/ChartOfAccounts/editChartOfAccountModal.vue";
export default {
  components: { addChartOfAccountModal, EditChartOfAccountModal },
  data() {
    return {
      search: "",
      headers: [
        { text: "", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Account Code", value: "account_code" },
        { text: "Account Title", value: "account_title" },
        { text: "Notes to FS", value: "notes_to_fs" },
        { text: "Normal Balance", value: "normal_balance" },
        { text: "Remarks", value: "remarks" },
        { text: "Date Created", value: "created_at" },
      ],
    };
  },
  created() {
    this.get_chart_of_accounts();
  },
  methods: {
    ...mapActions({
      get_chart_of_accounts: "chart_of_account/get_chart_of_accounts",
    }),
  },
  computed: {
    ...mapGetters({
      chart_of_accounts: "chart_of_account/chart_of_accounts",
    }),
  },
};
</script>
