<template>
  <div>
    <v-btn color="green" class="" icon x-large @click="dialog = true"
      ><v-icon>mdi-plus-box</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Add Chart of Account</v-card-title>
        <v-card-text>
          <v-text-field
            label="Account Code"
            v-model="form.account_code"
          ></v-text-field>
          <v-text-field
            label="Account Title"
            v-model="form.account_title"
          ></v-text-field>
          <v-text-field label="Remarks" v-model="form.remarks"></v-text-field>
          <v-select
            label="Notes to FS"
            v-model="form.notes_to_fs"
            :items="notes_to_fs"
          ></v-select>
          <v-select
            label="Normal Balance"
            :items="['debit', 'credit']"
            v-model="form.normal_balance"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      notes_to_fs: [
        "Drawings",
        "Expenses",
        "Assets",
        "Liabilities",
        "Equity",
        "Revenue",
        "Contra Revenue",
      ],
      dialog: false,
      loading: false,
      form: {},
    };
  },
  methods: {
    ...mapActions({
      add_chart_of_account: "chart_of_account/add_chart_of_account",
    }),

    submit() {
      this.loading = true;
      let x = window.confirm("Are you sure you want to add this entry?");
      if (x) {
        this.add_chart_of_account(this.form).then(() => {
          alert("Added a new chart of account entry");
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
